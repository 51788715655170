export const authTypes = {

  GET_SUBDOMAIN_SUCCESS: "GET_SUBDOMAIN_SUCCESS",

  //Login
  AUTH_LOGIN_REQUEST: "AUTH_LOGIN_REQUEST",
  AUTH_LOGIN_SUCCESS: "AUTH_LOGIN_SUCCESS",
  AUTH_LOGIN_FAILURE: "AUTH_LOGIN_FAILURE",

  //Logout
  AUTH_LOGOUT: "AUTH_LOGOUT",

  //Get Profile
  AUTH_GET_PROFILE_REQUEST: "AUTH_GET_PROFILE_REQUEST",
  AUTH_GET_PROFILE_SUCCESS: "AUTH_GET_PROFILE_SUCCESS",
  AUTH_GET_PROFILE_FAILURE: "AUTH_GET_PROFILE_FAILURE",
};
