import * as React from "react";

export class ComponentToPrint extends React.PureComponent {
  render() {
    const { image } = this.props;

    return (
      <div className="flex items-center justify-items-center justify-center">
        <img alt="print" src={image} className="object-center w-fit" />
      </div>
    );
  }
}

export const FunctionalComponentToPrint = React.forwardRef((props, ref) => {
  // eslint-disable-line max-len
  return <ComponentToPrint ref={ref} image={props.image} />;
});
