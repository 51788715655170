import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UrlPaymentService } from "../services/urlpayment.service";

const initialState = {
  items: null,
  status: null,
};

const UrlPayment = createSlice({
  name: "UrlPayment",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(GenerateUrlPayment.pending, (state, action) => {
        state.status = null
      })
      .addCase(GenerateUrlPayment.fulfilled, (state, action) => {
        state.items = action.payload;
        state.status = true
      })
      .addCase(GenerateUrlPayment.rejected, (state, action) => {
        state.items = action.error;
        state.status = false
       }),

});

export const GenerateUrlPayment = createAsyncThunk("UrlPayment/GenerateUrlPayment", async (data,{dispatch,getState}) => {

  try {

    const state = getState();
    const { domain } = state.auth;

    const _data = await UrlPaymentService.UrlPayment({
      ...data,
      domain
    });

  return _data;

} catch (error) {
    const message = error
    throw new Error(message.msg)

}
});

export default UrlPayment.reducer;

export const { } = UrlPayment.actions;

export const getUrlPayment = (state) => state.UrlPayment.items;

export const Getstatus = (state) => state.UrlPayment.status;
