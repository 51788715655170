import React, { useEffect } from "react";
import Layout from "../layouts/centered";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as actions from "../actions";

import ImgIllustration from '../assets/images/illustration.svg'

const Logout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.authLogout());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div className="flex flex-col items-center justify-center w-full max-w-xl text-center">
        <img
          className="object-contain w-auto h-64 mb-8"
          src={ImgIllustration}
          alt="svg"
        />
        <div className="mb-8 text-center text-grey-900">
          You have succesfully signed out.
        </div>

        <div className="flex w-full">
          <Link
            to="/login"
            className="btn btn-lg btn-rounded btn-block bg-blue-500 hover:bg-blue-600 text-white"
          >
            Login
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default Logout;
