import { createSlice } from "@reduxjs/toolkit";
import { createMerchantKeyReepay, fetchMerchantKeyReepay, fetchMerchantURL, getListOrder, updateMerchantURL } from "../actions";

const initialState = {
  items: null,
  pending: false,
  merchantUrl: null,
  merchantKey: null,
  updateMerchantURLStatus: "",
  updateMerchantKEYStatus: "",
};

const checkoutSlice = createSlice({
  name: "checkoutSlice",
  initialState,
  reducers: {
    resetUpdateUrlStatus: (state, action) => {
      state.updateMerchantURLStatus = "";
    },
    resetUpdateKeyStatus: (state, action) => {
      state.updateMerchantKEYStatus = "";
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getListOrder.pending, (state, action) => {
        state.pending = true;
      })
      .addCase(getListOrder.fulfilled, (state, action) => {
        console.log(action.payload);
        state.items = action.payload;
        state.pending = false;
      })
      .addCase(getListOrder.rejected, (state, action) => {
        state.items = [];
        state.pending = false;
      })

      .addCase(fetchMerchantURL.pending, (state, action) => {
        state.pending = true;
      })
      .addCase(fetchMerchantURL.fulfilled, (state, action) => {
        state.merchantUrl = action.payload?.result;
        state.pending = false;
      })
      .addCase(fetchMerchantURL.rejected, (state, action) => {
        state.merchantUrl = null;
        state.pending = false;
      })

      .addCase(updateMerchantURL.pending, (state, action) => {
        state.pending = true;
      })
      .addCase(updateMerchantURL.fulfilled, (state, action) => {
        state.updateMerchantURLStatus = "success";
        state.pending = false;
      })
      .addCase(updateMerchantURL.rejected, (state, action) => {
        state.updateMerchantURLStatus = "error";
        state.pending = false;
      })

      .addCase(fetchMerchantKeyReepay.pending, (state, action) => {
        state.pending = true;
      })
      .addCase(fetchMerchantKeyReepay.fulfilled, (state, action) => {
        //  state.merchantKey = action.payload?.keys[0];
        state.merchantKey = null;
        state.pending = false;
      })
      .addCase(fetchMerchantKeyReepay.rejected, (state, action) => {
        state.merchantKey = null;
        state.pending = false;
      })
      
      .addCase(createMerchantKeyReepay.pending, (state, action) => {
        state.pending = true;
      })
      .addCase(createMerchantKeyReepay.fulfilled, (state, action) => {
        state.updateMerchantKEYStatus = "success";
        state.pending = false;
      })
      .addCase(createMerchantKeyReepay.rejected, (state, action) => {
        state.updateMerchantKEYStatus = "error";
        state.pending = false;
      }),
});

export default checkoutSlice.reducer;

export const { resetUpdateUrlStatus ,resetUpdateKeyStatus } = checkoutSlice.actions;

export const selectListOrder = (state) => state.checkout.items;
export const selectMerchantURL = (state) => state.checkout.merchantUrl;
export const selectMerchantKey = (state) => state.checkout.merchantKey;

export const selectUpdateMerchantURLStatus = (state) =>
  state.checkout.updateMerchantURLStatus;

  export const selectUpdateMerchantKEYStatus = (state) =>
  state.checkout.updateMerchantKEYStatus;



  
