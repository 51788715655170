import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkoutService } from "../services";

export const getListOrder = createAsyncThunk(
  "checkout/getListOrder",
  async (params,  { dispatch, getState }) => {
    const state = getState();
    const { domain } = state.auth;
    const _data = await checkoutService.getListOrder({
      ...params,
      //domain
    });
    return _data;
  }
);

export const fetchMerchantURL = createAsyncThunk(
  "checkout/getMerchantURL",
  async (params, { dispatch, getState }) => {
    const state = getState();
    const { domain } = state.auth;
    const response = await checkoutService.getMerchantURL({
      ...params,
      //domain
    });
    return response.data;
  }
);

export const updateMerchantURL = createAsyncThunk(
  "checkout/putMerchantURL",
  async (params, { dispatch, getState }) => {
    const state = getState();
    const { domain } = state.auth;
    const response = await checkoutService.updateMerchantURL({
      ...params,
      //domain
    });
    return response.data;
  }
);

export const fetchMerchantKeyReepay = createAsyncThunk(
  "checkout/fetchMerchantKeyReepay",
  async (params, { dispatch, getState }) => {
    const state = getState();
    const { domain } = state.auth;
    const response = await checkoutService.getMerchantKeyReepay({
      ...params,
      //domain
    });
    return response.data;
  }
);

export const createMerchantKeyReepay = createAsyncThunk(
  "checkout/createMerchantKeyReepay",
  async (params, { dispatch, getState }) => {
    const state = getState();
    const { domain } = state.auth;
    const response = await checkoutService.createMerchantKeyReepay({
      ...params,
      //domain
    })
    return response.data;
  }
);
