import { useState, useEffect } from 'react';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [isMd, setIsMd] = useState(window.innerWidth >= 768)

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    setIsMd(window.innerWidth >= 768)
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial window size
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {...windowSize, isMd};
};

export default useWindowSize;
