import { createSlice } from "@reduxjs/toolkit";
import { fetchDashboardReport, fetchDashboardReportFilter } from "../actions";

const defaultTotal = {
  amount: 0,
  quantity: 0,
  card: 0,
  money: 0,
};

const initialState = {
  loading: false,
  error: null,
  data: defaultTotal,
  barData: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchDashboardReport.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchDashboardReport.fulfilled, (state, action) => {
        state.loading = false;
        state.barData = action.payload;
      })
      .addCase(fetchDashboardReport.rejected, (state, action) => {
        state.barData = null;
        state.loading = false;
        state.error = action.payload;
      })

      // num of transactions
      .addCase(fetchDashboardReportFilter.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchDashboardReportFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchDashboardReportFilter.rejected, (state, action) => {
        state.data = null;
        state.loading = false;
        state.error = action.payload;
      }),
});

export default dashboardSlice.reducer;

export const selectDashboardReport = (state) => state.dashboard.barData;
export const selectDashboardTotal = (state) => state.dashboard.data;
