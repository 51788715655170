import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchListTransInstallment } from "../actions";

const initialState = {
  items: null,
  pending:false,
};

const transInstallmentSlice = createSlice({
  name: "transInstallmentSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchListTransInstallment.pending, (state, action) => {
        state.pending = true
      })
      .addCase(fetchListTransInstallment.fulfilled, (state, action) => {
        state.items = action.payload.data;
        state.pending = false
      })
      .addCase(fetchListTransInstallment.rejected, (state, action) => {
        state.items = [];
        state.pending = false
       }),

});

// export const getListTS = createAsyncThunk("transaction/getListTS", async (data,{dispatch}) => {
//   const _data = await TSService.getListTransaction(data);
//   return _data;
// });

export default transInstallmentSlice.reducer;
export const { } = transInstallmentSlice.actions;

export const selectTransactionInstallment = (state) => state.transInstallment.items;
